import React  from "react"
import {useState} from "react"
import { Link } from 'gatsby'

// components
import Header from "../components/header"
import Banner from "../components/banner"
import MediaBox from "../components/media-box"
import JobListingTable from "../components/job-listing-table"
import Footer from "../components/footer"
import SEO from "../components/seo"
import MediaEmbed from "../components/media-embed"

import { Jumbotron, Container, Modal, Button } from 'react-bootstrap'

// images
import BackgroundImage from 'gatsby-background-image'
import grayLinesBG from '../images/background_stack.jpg'
import play from '../images/play.png'

const Page = ({ pageContext }) => {
  const post = pageContext
  //console.log(post.acfPageHeader);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const currentOpeningsBackgroundImageData = post.acfPositionPage.currentOpeningsBackgroundImage.localFile.childImageSharp.fluid
  const currentOpeningsBackgroundImageBackgroundFluidImageStack = [
      currentOpeningsBackgroundImageData,
      `linear-gradient(90deg,rgba(0,156,101,0.7),rgba(0,156,101,0.2))`,
    ].reverse()
  const videoBackgroundFluidImageStack = [
    post.acfPageHeader.videoBoxImage.localFile.childImageSharp.fluid,
    `linear-gradient(0deg,rgba(0,0,0,.3),rgba(0,0,0,.8))`,
  ].reverse()

  return (
    <main>
        <SEO
          title={post.title}
          description={post.acfSEO.metaDescription} 
          image={ (post.acfPageHeader.videoBoxImage.localFile.childImageSharp.fluid.src !== undefined ) ? post.acfPageHeader.videoBoxImage.localFile.childImageSharp.fluid.src : null }
          />
        <Header />
        <Banner
          title={post.acfPageHeader.headerTitle}
          subtitle={post.acfPageHeader.headerSubtitle}
          bodytext={post.acfPageHeader.headerBodyText}
          backgroundImage={post.acfPageHeader.bannerBackgroundImage}
          >
            
        </Banner>
        <div style={{backgroundImage: "url(" + grayLinesBG + ")", backgroundSize: '100%'}}>
          <div style={{postion:'relative', marginTop:'-25vh'}}>
            <MediaBox
              orientation="right"
              title={post.acfPageHeader.videoBoxTitle}
              subtitle={post.acfPageHeader.videoBoxSubtitle}
              videoImage={post.acfPageHeader.videoBoxImage}
              videoUrl={post.acfPageHeader.videoBoxVideoUrl}
              style={{border: '4px solid rgba(255,255,255,0.25)'}}
              >
                <div dangerouslySetInnerHTML={{__html: post.acfPageHeader.videoBoxBodyText}} className="pb-4" />
                <div className="d-lg-none mb-4">
                  <BackgroundImage
                    className="d-flex w-100"
                    Tag="div"
                    fluid={videoBackgroundFluidImageStack}
                    backgroundColor={`#040e18`}
                    style={{minHeight: "225px"}}
                  >
                    <div className="d-flex align-items-center justify-content-center w-100">
                      <button className="d-block h-100 w-100" style={{background: "none", border: "0"}} onClick={handleShow}>
                        <img src={play} alt="Play" />
                      </button>
                    </div>
                    <Modal show={show} onHide={handleClose} centered={true} size="xl">
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <MediaEmbed videoUrl={post.acfPageHeader.videoBoxVideoUrl}  autoplay={true} />
                      </Modal.Body>
                    </Modal>
                  </BackgroundImage>
                </div>
              <Button className="w-100 " href="#current-openings" style={{borderRadius:'20px'}}>View Current Openings</Button>
            </MediaBox>
          </div>
          <Container className="pb-5">
              <h4 className="text-primary text-center font-italic font-weight-bold mt-5 mb-0" style={{fontSize:'1.2rem', fontFamily: "Calendas"}}>
                {post.acfPositionPage.pageBodySubtitle}
              </h4>
              <h2 className="text-center text-uppercase mb-5">
                {post.acfPositionPage.pageBodyTitle}
              </h2>
              <div dangerouslySetInnerHTML={{__html: post.content}} />
          </Container>
        </div>
        {post.acfPositionPage.currentOpeningsBackgroundImage !== null
          ? <BackgroundImage
            className="text-center pt-5 pb-5"
            Tag="div"
            fluid={currentOpeningsBackgroundImageBackgroundFluidImageStack}
            backgroundColor={`#040e18`}
            style={{backgroundPosition: "0% 25%"}}
            >
              <Container className="bg-white mt-3 px-1 px-md-7 py-3"  id="current-openings">
                <h2 className="text-uppercase mb-0">Current Openings</h2>
                <h4 className="text-primary font-italic border-bottom pb-3" style={{fontSize:'1.2rem', fontFamily: "Calendas", marginBottom: "1.5rem"}}>{post.title}</h4>
                <div className="overflow-hidden"><JobListingTable showHeading="true" jobListings={post.jobListings} /></div>
                <div className="text-center pb-3">
                  <Link to="/current-openings" className="text-uppercase" style={{backgroundColor: '#1a302f', padding: "0.75rem 1.75rem", color: "#FFF", borderRadius: '20px'}}>See All Current Openings</Link>
                </div>
              </Container>
            </BackgroundImage>
          : <Jumbotron className="text-center" id="current-openings">
              <Container className="bg-white">
                <h2 className="text-uppercase mb-0">Current Opening</h2>
                <h4 className="text-primary font-italic mb-3">{post.title}</h4>
                <div className="overflow-hidden"><JobListingTable showHeading="true" jobListings={post.jobListings} /></div>
                <div className="text-center">
                  <Link to="/current-openings" className="text-uppercase" style={{backgroundColor: '#1a302f', padding: "0.75rem 1.75rem", color: "#FFF", borderRadius: '20px'}}>See All Current Openings</Link>
                </div>
              </Container>
            </Jumbotron>
        }

        <Footer />
    </main>
  )
}

export default Page
